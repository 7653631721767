import { useRef } from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00a858', //green[500],
        }
    },
});

export const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
        htmlElRef.current.focus();
    }

	return [ htmlElRef,  setFocus ] 
}


export function validarEmail(email) {
    const usuario = email.substring(0, email.indexOf("@"));
    const dominio = email.substring(email.indexOf("@")+ 1, email.length);
    
    if ((usuario.length >=1) &&
        (dominio.length >=3) &&
        (usuario.search("@")==-1) &&
        (dominio.search("@")==-1) &&
        (usuario.search(" ")==-1) &&
        (dominio.search(" ")==-1) &&
        (dominio.search(".")!=-1) &&
        (dominio.indexOf(".") >=1)&&
        (dominio.lastIndexOf(".") < dominio.length - 1)) {
        return true
    }
    return false;
}

export const Meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold, //.fontWeightRegular,
    },
    subHeading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
        margin: theme.spacing(0, 3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    paperNormal: {
        padding: theme.spacing(2)
    },
    btns: {
        '& > *': {
            margin: theme.spacing(1)
        },
        display: 'flex'
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    tableHead: {
        backgroundColor: '#45b97c',
        color: theme.palette.common.white,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    btnInLine: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        // width: 400,
      },
    multilineColor:{
        color:'red'
    }
}), { index : 1 });

export function fluxoDespacho(row, fluxo, fluxoDiverso, fluxoComplementoNotificacao){
    const ultimoFluxo = row.fluxoPath? row.fluxoPath[row.fluxoPath.length-1]:'';
    
    if( !fluxo || !fluxoDiverso) return "";
    if( ultimoFluxo){
        const item = fluxo.nodes.find(n => n.node === ultimoFluxo);
        if( item ){
            return item.nome;
        }else{
            const itemD = fluxoDiverso.nodes.find(n => n.node === ultimoFluxo);
            if( itemD ){
                return itemD.nome;
            }else{
                if( fluxoComplementoNotificacao ){
                    const itemCN = fluxoComplementoNotificacao.nodes.find(n => n.node === ultimoFluxo);
                    if( itemCN ){
                        return itemCN.nome;
                    }
                }
            }
        }
    }
    return "Em Analise"   
}
export function showStatusEmpresario(row){
    const st = row.status;
    const lastFluxo = row.fluxoPath ?row.fluxoPath[row.fluxoPath.length-1]:'';
    
    if( st === 'RASCUNHO'){
        return "Rascunho"
    }else if ( st === 'PENDENTE'){
        return "Pendente";
    }else if ( st === 'REPROVADO'){
        return "Reprovado";
    }else if ( st === 'DEFERIDO'){
        if( lastFluxo === 'deferido'){
            return "Deferido";
        }
        return "Em análise"
    }else if ( st === 'INDEFERIDO'){
        if( lastFluxo === 'indeferido'){
            return "Indeferido";
        }
        return "Em análise"
    }else{
        if( lastFluxo === 'deferido'){
            return "Deferido";
        }else if( lastFluxo === 'indeferido'){
            return "Indeferido";
        }
        return "Em análise"
    }
}

export const API_BASE_URL = 'https://app.semp.hml.verytecnologia.com.br';
//export const API_BASE_URL = 'http://'+document.location.hostname+':8080'
export const ACCESS_TOKEN = 'accessToken';

export const getQueryParams = (s) => {
    if (!s || typeof s !== 'string' || s.length < 2) {
      return new Map();
    }
    const a = s
      .substr(1) // remove `?`
      .split('&') // split by `&`
      .map(x => {
        const a = x.split('=');
        return [a[0], a[1]];
      }); // split by `=`
  
    return new Map(a);
  };

// export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'

export const OAUTH2_REDIRECT_URI = document.location.origin + '/oauth2/redirect';


// export const OAUTH2_REDIRECT_URI = 'https://'+document.location.hostname+(document.location.port.length>0?':'+document.location.port:'')+'/oauth2/redirect';

export const GOOGLE_AUTH_URL = API_BASE_URL + '/api/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/api/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/api/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const WSO2_AUTH_URL = API_BASE_URL + '/api/oauth2/authorize/customClient?redirect_uri=' + OAUTH2_REDIRECT_URI;

export function FormataMoeda(val){
    if( val === 0 || val === '0')return val.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;
    if( !val) return '';
    var f = val.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    return f;
}

export function FormataNumeroInteiro(val){
    if( val === 0 || val === '0')return val.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});;
    if( !val) return '';
    var f = val.toLocaleString('pt-br');
    return f;
}
export function ValidarCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    let i = 0;
    if (strCPF.length !== 11 ||
        strCPF === "00000000000" ||
        strCPF === "11111111111" ||
        strCPF === "22222222222" ||
        strCPF === "33333333333" ||
        strCPF === "44444444444" ||
        strCPF === "55555555555" ||
        strCPF === "66666666666" ||
        strCPF === "77777777777" ||
        strCPF === "88888888888" ||
        strCPF === "99999999999")
        return false;

    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}
export function FormataData(data) {
    var dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
}       
const mapTipo = [
    ["EM_ANALISE"," Requerimento solicitado. "],
    ["vinculo"," Requerimento vinculado. "],
    ["despachoDiretoria"," Despacho Diretoria. "],
    ["despachoSubSecretaria"," Despacho SubSecretaria. "],
    ["valUnate"," Avaliado pela UNATE. "],
    ["naoRespondido"," Encaminhado ao empresário. "],
    ["responder"," Notificação respondida. "],
    ["analiseSub"," Encaminhado a SEDET. "],
    ["REPROVADO"," Requerimento reprovado. "],
    ["DESPACHO"," Requerimento validado UNATE. "],
    ["DEVOLVIDO"," Despacho devolvido. "],
    ["Reenviado"," Requerimento reenviado. "],
    ["unidade"," Vinculo unidade. "],
    ["PENDENTE"," Pendente. "],
    ["valGestaoUnate"," Validado pela Gerencia UNATE. "],
    ["motivoMudarUnidade", "Emissão do Relatório Técnico"],
    ["ENCAMINHADO_UNIDADE", "Encaminhado pela UNATE"],
    ["Requerimento vinculado ao SEI", "Requerimento vinculado ao SEI"]
]

export function deParaTipoHitorico(tipo){
   let x = mapTipo.find(item=> item[0]=== tipo);
   return x?x[1]:'';
}
export function FormataDataComHora(data) {
    var dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();

    let seconds = data.getSeconds().toString();
    let minutes = data.getMinutes().toString();
    let hour = data.getHours().toString();

    hour = (hour.length == 1) ? '0' + hour : hour;
    minutes = (minutes.length == 1) ? '0' + minutes : minutes;
    seconds = (seconds.length == 1) ? '0' + seconds : seconds;

    return diaF + "/" + mesF + "/" + anoF + " " + hour + ":" + minutes + ":" + seconds;
}

export function FormataDataSemHora(data) {
    var dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();

    let seconds = data.getSeconds().toString();
    let minutes = data.getMinutes().toString();
    let hour = data.getHours().toString();

    hour = (hour.length == 1) ? '0' + hour : hour;
    minutes = (minutes.length == 1) ? '0' + minutes : minutes;
    seconds = (seconds.length == 1) ? '0' + seconds : seconds;

    return diaF + "/" + mesF + "/" + anoF + " ";
}
export function FormataCnpj(cnpj){
    if( !cnpj) return "";
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}
export function calculaDiaLimite(dt){
    if (!dt) return '';
    const timeDiff  =  new Date() - ( new Date(dt) );
    const days      = timeDiff / (1000 * 60 * 60 * 24);
    if( days >= 30 )return "";
    return Math.round( 30 - days );
}
export function ValidarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj === '') return false;

    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    let i = 0;
    let resultado = 0;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

export function filtroDadoGenerico(fonte, filtrado, filtro){
    root: 
    for( let xx in fonte){
        const d= fonte[xx];
        if (filtro) {
            
            let campos = [];
            if( filtro.includes('"')){
                campos.push( filtro.split('"')[1])
            }else {
                campos = filtro.split(' ');
            }
            filho: 
            for( let x in d ){
                for (var y in campos) {
                    if( x.includes("data")){
                        let val = FormataDataComHora( new Date(d[x]));
                        if( val.includes( campos[y])){
                            filtrado.push(d);
                            continue root;
                        }
                    }else if( typeof d[x] === 'string'){
                        
                        if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                            filtrado.push(d);
                            continue root;
                        }    
                    }else if( typeof d[x] === 'object'){
                        if( buscaRecursivo(campos[y], d[x])){
                            filtrado.push(d);
                            continue root;  
                        }
                    }else if( d[x] == campos[y]){
                        filtrado.push(d);
                        continue root;
                    }
                }
            }
        } else {
            filtrado.push(d);
        }
    }
}
function buscaRecursivo(filtro, dado){
    for( const x in dado){
        if( x.includes("data")){
            let val = FormataDataComHora( new Date(dado[x]));
            if( val.includes( filtro)){
                return true;
            }
        }else if( typeof dado[x] === 'string'){
            if (dado[x].toUpperCase().includes(filtro.toUpperCase())) {
                return true;
            }    
        }else if( typeof dado[x] === 'object'){
            if( buscaRecursivo(filtro, dado[x])){
                return true;   
            }
        }else if( dado[x] == filtro){
            return true;
        }
    }
    return false;
}